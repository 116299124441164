import React, { useState } from "react"
import { navigate } from "gatsby"
import { useIntl } from "react-intl"

const GetStartedCTA = () => {

  const intl = useIntl()

  const [address, setAddress] = useState("")

  const handleAddressChange = e => setAddress(e.target.value)
  const handleSubmit = e => {
    e.preventDefault();
    navigate(intl.formatMessage({ id: "url.map-my-customers" }), { state: { address }})
  }

  return (
    <div className="container">
      <div className="has-text-centered">
        <h2 className="title">{intl.formatMessage({ id: "get_started_cta.get_started" })}</h2>
        <div className="column">
          <form onSubmit={handleSubmit}>
            <input className="input is-rounded is-primary is-large" type="text" 
              placeholder={intl.formatMessage({ id: "get_started_cta.your_business_address" })}
              value={address}
              onChange={handleAddressChange} />
            <br />
            <br />
            <input className="button is-primary is-rounded is-large" type="submit" 
              value={intl.formatMessage({ id: "get_started_cta.find_customers" })} />
          </form>
        </div>
      </div>
    </div>
  )
}

export default GetStartedCTA
