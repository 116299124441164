import React, { useEffect, useContext, useRef } from "react"
import { graphql, Link } from "gatsby"
import { useIntl } from "react-intl"

import Layout from "../../layout"
import SEO from "../../seo"
import EmailCaptureModal from "../../email-capture-modal"
import GetStartedCTA from "../../get-started-cta"
import { IntlContext } from "../../../intl"

import "./homepage.scss"

export const query = graphql`
	query($id: String!) {
		prismicHomepage(id: { eq: $id }) {
      url
      lang
			data {
				title {
					text
				}
				above_the_fold_headline {
					text
				}
				above_the_fold_tagline {
					text
				}
        above_the_fold_button_text
				section_one_headline {
					text
				}
        secondary_headline {
          text
        }
        secondary_button_text
				section_one_content {
					html
				}
				section_two_headline {
					text
				}
				section_two_content {
					html
				}
				testimonials_headline {
					text
				}
				testimonials_tagline {
					text
				}
        background_image {
          alt
          copyright
          fluid(maxWidth: 1600) {
            src
          }
        }
        above_the_fold_image {
          alt
          fluid {
            src
          }
        }
        section_one_image {
          alt
          fluid {
            src
          }
        }
        section_two_image {
          alt
          fluid {
            src
          }
        }
        cta_image {
          alt
          fluid {
            src
          }
        }
			}
      alternate_languages {
        lang
        url
        document {
          __typename
          ... on PrismicHomepage {
            data {
              title {
                text
              }
            }
          }
        }
      }
		}
	}
`

const Homepage = ({ data }) => {

  const modalTriggerRef = useRef()
  const intl = useIntl()

  const { changeLocale, setAlts } = useContext(IntlContext)
  useEffect(() => {
    changeLocale(data.prismicHomepage.lang);
    setAlts(data.prismicHomepage.alternate_languages || [])
  }, [changeLocale, setAlts, data])
  
  return (
    <Layout container={false}>
      <SEO 
        lang={data.prismicHomepage.lang} 
        title={data.prismicHomepage.data.title.text} 
        meta={[{
          "http-equiv": "content-language", 
          content: data.prismicHomepage.lang
        },{
          property: `og:title`,
          content: data.prismicHomepage.data.title.text,
        }]} 
        links={data.prismicHomepage.alternate_languages.concat([data.prismicHomepage]).map(alt => ({
          rel: "alternate", 
          hreflang: alt.lang, 
          href: `https://www.customerradius.com${alt.url}`
        })).concat([{
          rel: "alternate", 
          hreflang: "x-default", 
          href: "https://www.customerradius.com/"
        }])}
      />
      {/* Above the Fold */}
      <section className="hero is-fullheight-with-navbar">
        <div className="hero-body has-background-warning box is-radiusless">
          <div className="container has-text-centered-touch">
            <h1 className="title is-size-1-desktop">
              {data.prismicHomepage.data.above_the_fold_headline.text}
            </h1>
            <h2 className="subtitle is-size-3-desktop">
              {data.prismicHomepage.data.above_the_fold_tagline.text}
            </h2>
            <Link to={intl.formatMessage({ id: 'url.map-my-customers' })} className="button is-primary is-large is-rounded">
              {data.prismicHomepage.data.above_the_fold_button_text}
            </Link>
          </div>
        </div>
        <div className="hero-footer py-5">
          <div className="container">
            <div className="columns is-flex-reverse-touch">
              <div className="column is-two-fifths-desktop">
                <div className="section">
                  <div className="content">
                    <h4 className="subtitle">
                      {data.prismicHomepage.data.secondary_headline.text}
                    </h4>
                    <Link to={intl.formatMessage({ id: "url.earlyaccess" })} className="button is-dark is-outlined">
                      {data.prismicHomepage.data.secondary_button_text}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="column is-three-fifths-desktop">
                <figure className="image">
                  <img src={data.prismicHomepage.data.above_the_fold_image.fluid.src}
                    alt={data.prismicHomepage.data.above_the_fold_image.alt} />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Section One */}
      <section className="hero is-fullheight-with-navbar has-background-light">
        <div className="hero-body">
          <div className="container">
            <div className="columns has-text-centered is-vcentered is-flex-reverse-touch">
              <div className="column">
                <img style={{width:"400px"}} src={data.prismicHomepage.data.section_one_image.fluid.src}
                  alt={data.prismicHomepage.data.section_one_image.alt} />
              </div>
              <div className="column mb-5">
                <h2 className="title">{data.prismicHomepage.data.section_one_headline.text}</h2>
                <div className="content is-large" dangerouslySetInnerHTML={{ __html: data.prismicHomepage.data.section_one_content.html }}></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Section Two */}
      <section className="hero is-fullheight-with-navbar">
        <div className="hero-body">
          <div className="container">
            <div className="columns has-text-centered is-vcentered">
              <div className="column mb-5">
                <div className="">
                <h2 className="title">{data.prismicHomepage.data.section_two_headline.text}</h2>
                <div className="content is-large" dangerouslySetInnerHTML={{ __html: data.prismicHomepage.data.section_two_content.html }}></div>
                  {/* <Link to="/hyperlocal-marketing">
                    <button className="button">
                      Hyperlocal Marketing
                    </button>
                  </Link>
                  {` `}
                  <Link to="/integrated-direct-mail-marketing">
                    <button className="button">
                      Integrated Marketing
                    </button>
                  </Link> */}
                </div>
                
              </div>
              <div className="column">
                <img style={{width:"400px"}} src={data.prismicHomepage.data.section_two_image.fluid.src}
                  alt={data.prismicHomepage.data.section_two_image.alt} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Testimonials */}
      {/* <section className="hero is-fullheight-with-navbar is-light">
        <div className="hero-body">
          <div className="container">
            <div className="has-text-centered">
              <h2 className="title">{data.prismicHomepage.data.testimonials_headline.text}</h2>
              <h2 className="subtitle">{data.prismicHomepage.data.testimonials_tagline.text}</h2>
              <div className="columns">
                <div className="column">
                  <div className="card">
                    <div className="card-content">
                      Testimonial
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="card">
                  <div className="card-content">
                      Testimonial
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="card">
                  <div className="card-content">
                      Testimonial
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* CTA */}
      <section className="hero is-fullheight-with-navbar is-light">
        <div className="hero-body">
          <div className="container">
            <div className="columns has-text-centered is-vcentered">
              <div className="column">
                <img className="box" style={{margin:"0 auto"}} src={data.prismicHomepage.data.cta_image.fluid.src}
                  alt={data.prismicHomepage.data.cta_image.alt} />
              </div>
              <div className="column mb-5">
                <GetStartedCTA />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="hero is-primary">
				<div className="hero-body has-text-centered">
					<button className="button is-light is-outlined is-large" ref={modalTriggerRef}>
						{intl.formatMessage({ id: "email_capture.request_early_access" })}
					</button>
          <EmailCaptureModal triggerRef={modalTriggerRef} intl={intl} sourceDetail={'Homepage'} />
				</div>
			</div>
    </Layout>
  )
}

export default Homepage
